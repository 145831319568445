<template>
  <thead>
    <transition-group name="fade" tag="tr">
      <th v-if="shouldRenderSelection" key="--th-multi">
        <multi-select style="margin-left: 7px !important; vertical-align: middle;" :selection="selection" :rows="data" />
      </th>
      <th v-for="(col, idx) in tableColumns"
        :key="col.title || col.field || idx"
        :class="(col.thClass !== null && typeof col.thClass !== 'undefined') ? col.thClass : 'text-center align-middle p-1' " :style="col.thStyle">
        <!-- <th> component (thComp) -->
        <component
          v-if="col.thComp"
          :is="forDynCompIs(col.thComp)"
          :column="col"
          :field="col.field"
          :title="col.title"
          v-bind="$props">
          <head-sort slot="sort" v-if="col.sortable" :field="col.field" :query="query" :field_sort="col.field_sort"/>
        </component>
        <template v-else>
          <div style="margin-right: 8px" @click="clickOnHeader(col.field)">{{ col.title }}</div>
          <head-sort :ref="col.field" v-if="col.sortable" :field="col.field" :query="query" :field_sort="col.field_sort"/>
        </template>
        <i v-if="col.explain" class="fa fa-info-circle" style="cursor: help" :title="col.explain"></i>
      </th>
    </transition-group>
  </thead>
</template>
<script>
import HeadSort from './HeadSort.vue'
import MultiSelect from './MultiSelect.vue'
import props from '../_mixins/props'
import shouldRenderSelection from '../_mixins/shouldRenderSelection'

export default {
  name: 'TableHeader',
  components: { HeadSort, MultiSelect },
  mixins: [props, shouldRenderSelection],
  methods: {
    clickOnHeader(field) {
      if (this.$refs[field])
        this.$refs[field][0].handleClick()
    }
  },
  computed: {
    tableColumns() {
      if(this.leftFixed){
        return this.columns.filter(col => col.fixed === true || col.fixed === 'left' );
      } else if (this.rightFixed){
        return this.columns.filter(col => col.fixed === 'right' );
      } else {
        return this.columns.filter(col => col.fixed !== true && col.fixed !== 'left' && col.fixed !== 'right');
      }
    }
  }
}
</script>
