<template>
 <label name="PageSizeSelect">
   <select class="form-control input-sm -page-size-select" v-model="query.limit"
     @change="query.offset = 0 /* reset to the first page */">
      <option v-for="(i, index) in pageSizeOptions" :value="i" :key="index">{{ i }}</option>
   </select>
   {{ $i18nForDatatable('items / page') }}
 </label>
</template>
<script>
export default {
  name: 'PageSizeSelect',
  props: {
    query: { type: Object, required: true },
    pageSizeOptions: { type: Array, required: true }
  }
}
</script>
<style>
.-page-size-select {
  margin: 0;
  width: 65px;
  border-radius: 0 !important;
 
}
.-page-size-select-label {
  margin: 0;
}
</style>
