import {HOST} from '../../config.js'
const URI_LIST_POSTS = 'api/news/posts/'
const URI_LIST_HOUSES = 'api/geo/houses'
export default {
  api_list_posts: function () {
    return HOST + URI_LIST_POSTS
  },
  api_post_detail: function (id) {
    return HOST + URI_LIST_POSTS + id
  },
  api_list_houses_search: function () {
    return HOST + URI_LIST_HOUSES + '/search?limit=1000'
  },
  api_post_public_unpublic(id) {
    return `${this.api_post_detail(id)}/public-unpublic-update`
  }
}
