import axios from 'axios'
import _ from 'lodash'

export default {
  data: () => ({
    cancelRequest: null,
    watchersSummary: [],
    watchersFilter: []
  }),
  beforeDestroy() {
    this.unWatchFilter()
    this.unWatchSummary()
  },
  methods: {
    remove(instances, current_row, url=null) {
      let url_for_remove =  url == null ? this.url_detail() : url
      return new Promise((resolve, reject) => {
        this.$Progress.start()
        axios.delete(url_for_remove)
        .then(() => {
          this.reloadRow(instances.data, current_row.id)
          if (instances.count)
            instances.total -= 1
          this.$Progress.finish()
          resolve(true)
        })
        .catch((e) => {
          this.showError(e, 'Ошибка удаления объекта')
          this.$Progress.fail()
          reject(false)
        })
      })
    },

    unWatchFilter() {
      this.watchersFilter.forEach(function (unwatch) {
        if (unwatch) {
          unwatch()
        }
      })
    },
    unWatchSummary() {
      this.watchersSummary.forEach(function (unwatch) {
        if (unwatch) {
          unwatch()
        }
      })
    },
    watchInit(array_data) {
      this.watchSummary(array_data)
      this.watchFilter(array_data)
    },
    watchSummary(array_data) {
      let watch = this.$watch([array_data.name, 'selection'].join('.'), (rows) => {
        for(let key_summary in array_data.selected_summary) {
          array_data.selected_summary[key_summary] = 0
        }
        if (rows.length > 0) {
          for(let key_row in rows) {
            for(let key_summary in array_data.selected_summary) {
              array_data.selected_summary[key_summary] += Number(rows[key_row][key_summary])
              // округление исходя из параметров
              if (array_data.round_summary && array_data.round_summary[key_summary]) {
                array_data.selected_summary[key_summary] = Number(array_data.selected_summary[key_summary].toFixed(array_data.round_summary[key_summary]))
              }
            }
          }
        }
      })
      this.watchersSummary.unshift(watch)
    },
    watchFilter(array_data) {
      //Иницилизация фильтров на основе таблицы и ее фильтров
      for(let filter in array_data.filters) {

        let settings
        let watch

        if (array_data.filter_settings && filter in array_data.filter_settings)
          settings = array_data.filter_settings[filter]
        // фильтр с задержкой
        if (settings && 'debounce' in settings && settings['debounce'] === true) {
          watch = this.$watch([array_data.name, 'filters', filter].join('.'), _.debounce(function(value) {
            this.$set(array_data.query, filter, value)
          }, 500))
        // с минимальным количеством символов
        } else if (settings && 'min' in settings) {
          watch = this.$watch([array_data.name, 'filters', filter].join('.'), function (value) {
            if (value.length >= settings['min']) {
              this.$set(array_data.query, filter, value)
            }
          })
        // если груповой фильтр, те. по нескольким полям сразу
        } else if (settings && 'group' in settings && settings['group'] === true){
          watch = this.$watch([array_data.name, 'filters', filter].join('.'), function (value) {
            array_data.query = Object.assign({}, array_data.query, value)
          })
        }
        // все остальное
        else {
          watch = this.$watch([array_data.name, 'filters', filter].join('.'), function(value) {
            this.$set(array_data.query, filter, value)
          })
        }
        if (watch)
          this.watchersFilter.unshift(watch)
      }
    },
    reloadRow(data, id, row) {
      let indexRow = null
      data.forEach(function (item, i) {
        if (Number(item.id) === Number(id)) {
          indexRow = i
        }
      })
      if (indexRow !== null) {
        if (row != null){
         data.splice(indexRow, 1, row)
        } else {
         data.splice(indexRow, 1)
        }
        return indexRow
      } else return null
    },
    addRow(data, row, table=null) {
      data.unshift(row)
      if (table)
        this.$refs[table].select(row.id)
    },
    getTotalTable(url, array_data) {
      axios.get(url)
      .then(response => {
        array_data.total_by_all = response.data.data.total
      }).catch(() => {})
    },
    getDataTable(url, array_data, cancel, progress=true) {
      return new Promise((resolve, reject) => {
        if (this.cancelRequest && cancel !== false) {
          this.cancelRequest.cancel('cancel')
        }
        if (progress)
          this.$Progress.start()
        this.cancelRequest = axios.CancelToken.source()
        array_data.loading_data_table = true
        axios.get(url, {cancelToken: this.cancelRequest.token})
            .then(response => {
              array_data.next = response.data.next
              array_data.data = response.data.data
              if (response.data.count !== null)
                array_data.total = response.data.count
              if (response.data.total)
                array_data.total_by_all = response.data.total
              array_data.loading_data_table = false
              if (progress)
                this.$Progress.finish()
              this.$nextTick(() => {
                if (array_data['name'] && this.$refs[array_data['name']])
                  this.$refs[array_data['name']].setFirstSelected()
              })
              resolve(true)
            })
            .catch((e) => {
              array_data.loading_data_table = false
              if (e) {
                if (progress)
                  this.$Progress.fail()
                this.showError(e, 'Ошибка получения данных')
              } else {
                if (progress)
                  this.$Progress.finish()
              }
              reject(false)
            })
      })
    },
    handleScroll(array_data) {
      if (array_data.next && !this._inactive) {
        array_data.loading_data_table = true
        axios.get(array_data.next)
        .then(response => {
          array_data.data.push(...response.data.data)
          array_data.loading_data_table = false
          array_data.next = response.data.next
          if (response.data.count)
            array_data.total = response.data.count
        })
        .catch((e) => {
          this.showError(e, 'Ошибка получения данных')
          array_data.loading_data_table = false
        })
      }
    }
  }
}