<template>
    <div id="wrapper">
      <div class="sidebar" data-color="green" data-background-color="black">
        <div class="logo">
          <a class="ml-5 simple-text logo-normal">
            Панель управления
          </a>
        </div>
        <div class="sidebar-wrapper">
          <ul class="nav">
            <li class="nav-item ">
              <a @click="open('/news', 'Новости')" href="javascript:;" class="nav-link">
                <i class="fas fa-newspaper fa-sm text-white-50"></i>  <p>Новости</p>
              </a>
            </li>
            <li class="nav-item ">
              <a @click="open('/statistic', 'Статистика')" href="javascript:;" class="nav-link">
                <i class="fas fa-chart-bar fa-sm text-white-50"></i><p>Статистика</p>
              </a>
            </li>
            <li class="nav-item ">
              <a @click="open('/org-info', 'Информация')" href="javascript:;" class="nav-link">
                <i class="fas fa-info fa-sm text-white-50"></i>  <p>Информация об УК</p>
              </a>
            </li>
          </ul>
        </div>
        <div class="sidebar-background sidebar-background-image"></div>
      </div>

      <div class="main-panel">
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
          <div class="container-fluid">
          <div class="navbar-wrapper">
            <div class="navbar-minimize">
              <button @click="toggleMenu" id="minimizeSidebar" class="btn btn-just-icon btn-white btn-fab btn-round">
                <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
              </button>
            </div>
            <a class="navbar-brand" href="#pablo">Панель</a>
          </div>
          <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span class="sr-only">Навигатор</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span class="mr-2 d-none d-lg-inline text-gray-600">{{$store.state.user.name}}</span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                  <a class="dropdown-item" @click="$store.commit('LOGOUT', {redirect: '/'})" href="#">
                    <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Выход
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        </nav>
        <div class="content" style="margin-top:40px">
          <div class="container-fluid">
            <div class="row">
              <main role="main" class="col-md-12 col-lg-12 pt-1 px-3">
                <template v-if="tabs.length > 0">
                  <div class="card">
                    <div class="card-header card-header-tabs card-header-blue">
                      <div class="nav-tabs-navigation">
                        <div class="nav-tabs-wrapper">
                          <ul class="nav nav-tabs" data-tabs="tabs">
                            <li
                              v-for="(tab, key) in tabs"
                              :key="`title-${key}`"
                              class="nav-item mr-2">
                              <a
                                style="cursor: pointer;padding-left: 3px"
                                class="nav-link"
                                :class="{'active': selected === tab.name}"
                                @click="select(tab)">
                                <span v-if="tab.notify === true" class="text-danger">•</span>
                                {{ tab.label }}
                                <span class="tab-closer" @click="close(tab, $event)">&times;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <Container />
                  </div>
                  </div>
                </template>
                <div v-else class="alert alert-white alert-with-icon" data-notify="container">
                  <i class="material-icons" data-notify="icon">info</i>
                  <span data-notify="message">Приступая к работе необходимо выбрать меню.</span>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import Container from './Container'
export default {
  name: 'Dashboard',
  components: {
    Container
  },
  props: {
    base: {
      type: String,
      default: () => ''
    }
  },
  mounted: function () {
    this.$root.$on('open', this.open);
    this.$root.$on('mark_tab', this.markTab);
    this.open('/news', 'Новости');
  },
  beforeDestroy: function () {
    this.$root.$off('open', this.open);
    this.$root.$off('mark_tab', this.markTab);
  },
  data: () => ({
    selected: '',
    last: '',
    counter: 0,
    tabs: []
  }),
  methods: {
    markTab (model, id) {
      if (id != null) {
        let tab = this.tabs.find(item => model === item.model && id === item.id)
        if (tab)
          tab.notify = true
      } else {
        let tab = this.tabs.find(item => model === item.model)
        if (tab)
          tab.notify = true
      }
    },
    select (tab) {
      if (tab) {
        tab.notify = false
        this.last = this.selected
        this.selected = tab.name
        if (!this.last) {
          this.last = this.selected
        }
        this.go(tab)
      }
    },
    create (label, path, model, id) {
      this.counter++
      const name = 'tab-' + this.counter
      if (path.substring(0, 1) === '/') {
        path = path.substring(1)
      }
      return {
        path: `${this.base}/${name}/${path}`,
        name: name,
        label: label,
        model: model,
        id: id,
        notify: false
      }
    },
    add (tab) {
      this.tabs.push(tab)
    },
    close (tab, $event) {
      $event.preventDefault()
      $event.stopPropagation()
      const index = this.tabs.findIndex(item => tab.name === item.name)
      this.tabs.splice(index, 1)
      let last = this.tabs.find(item => this.last === item.name)
      if (!last) {
        last = this.tabs.slice(-1)[0]
      }
      this.$root.$emit('close_tab_' + tab.name);
      this.select(last)
    },
    open (path, label, model, id) {
      let tab = this.tabs.find(item => label === item.label)
      if (tab) {
        return this.select(tab)
      }
      tab = this.create(label, path, model, id)
      this.add(tab)
      this.select(tab)
    },
    go (tab) {
      this.$router.push(tab.path).catch(error => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    }
  },
  watch: {
    $route () {
      const name = this.$route.params.tab
      const index = this.tabs.findIndex(item => name === item.name)
      if (index >= 0) {
        this.tabs[index].path = this.$route.fullPath
      }
    }
  }
}
</script>

<style>

</style>
