<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto mt-4">
        <div class="card card-login">
          <div class="card-header card-header-info text-center">
            <h4 class="card-title">Вход в панель управления</h4>
          </div>
          <div class="card-body ">
            <ValidationObserver ref="observer">
              <validation-provider name="логин" rules="required" v-slot="{ errors }">
                <p class="card-description text-center">Ваши логин и пароль</p>
                <div class="form-group">
                  <input v-model="credentials.username" id="login" name="login" @keyup.enter="submit" type="text" class="form-control" placeholder="Введите логин...">
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <validation-provider name="пароль" rules="required" v-slot="{ errors }">
                <div class="form-group mt-2">
                  <input type="password" id="password" name="password" class="form-control" placeholder="Введите пароль" v-model="credentials.password" @keyup.enter="submit">
                  <span class="text-danger">{{ errors[0] }}</span>
                </div>
              </validation-provider>
              <div v-if="login_errors.length" class="errors">
              <ul>
                <li class="text-danger ml-3" :key="index" v-for="(error, index) in login_errors">{{ error }}</li>
              </ul>
            </div>
            </ValidationObserver>
            <div v-if="login_errors.length" class="errors">
              <ul>
                <li class="text-danger ml-3" :key="index" v-for="(error, index) in login_errors">{{ error }}</li>
              </ul>
            </div>
          </div>
          <div class="card-footer justify-content-center">
            <a @click="submit" :disabled="loading===true"  class="btn btn-success btn-link btn-lg"><i v-show="loading" class="fa fa-refresh fa-spin"></i> Далее<div class="ripple-container"></div></a>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import urls from './urls'
import axios from 'axios'
import * as types from '../../store/mutation-types'
import '@/validation.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
export default {
  components: {ValidationProvider, ValidationObserver},
  data () {
    return {
      loading: false,
      credentials: {
        username: '',
        password: ''
      },
      login_errors: []
    }
  },
  computed: {
    token () {
      return this.$store.state.token
    }
  },
  mounted: function () {

  },
  methods: {
    submit () {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.loading = true
          axios.defaults.withCredentials = true
          let url = urls.api_validate_login()
          axios.post(url,
            {
              username: this.credentials.username,
              password: this.credentials.password
            })
            .then((response) => {
              let mutationPayload = {}
              let redirect = decodeURIComponent(this.$route.query.redirect || '/')
              mutationPayload.token = response.data.access
              mutationPayload.refresh = response.data.refresh
              mutationPayload.user = response.data.user
              mutationPayload.redirect = redirect
              this.$store.commit(types.LOGIN, mutationPayload)
              this.loading = false
            })
            .catch((error) => {
              this.loading = false
              this.login_errors = [error.data.error_list['error'][0]]
            })
        }
      })
    }
  }
}
</script>
<style scoped>
</style>
