import axios from 'axios'

export default {
  props: {
    params: {},
    url_list: {},
    url_detail: {},
    title: {default: ''},
    save_local: {type: Array, default: () => []}
  },
  data: () => ({
    loading_detail: false,
    loading_saving: false,
    instance: {id: null},
    extra: {},
    method: 'create'
  }),
  methods: {
    resetForm() {
      // убрать надо с переходом на новый validator
      /*this.$validator.pause()
      this.$nextTick(() => {
      this.$validator.resume()
      })*/
      Object.assign(this.$data, this.$options.data.call(this));
    },
    getUrl(obj) {
      if (typeof obj === "function") {
          return obj()
      }
      return obj
    },
    afterGetDetail() {},
    except(e, message_error='Ошибка создания объекта') {
      if (e) {
        if ('confirm' in e.data && e.data.confirm === true) {
          this.showConfirm(e.data)
        } else {
          // если в error_list по каждому полю есть описание ошибки то выведем в форму все ошибки
          if ('error_list' in e.data && typeof (e.data['error_list'][0]) === 'undefined' && !('error' in e.data['error_list'])) {
            this.showValidationError(e.data)
          } else {
            this.showError(e, message_error)
          }
        }
      }
    },
    getDetail() {
      return new Promise((resolve, reject) => {
        this.loading_detail = true
        axios.get(this.getUrl(this.url_detail))
        .then(response => {
          this.instance = response.data.data
          this.loading_detail = false
          this.afterGetDetail()
          resolve(true)
        })
        .catch((e) => {
          this.showError(e, 'Ошибка получения данных')
          this.loading_detail = false
          reject(false)
          this.afterGetDetail()
        })
      })
    },
    showValidationError(data) {
      if ('observer' in this.$refs && 'error_list' in data) {
        this.$refs.observer.setErrors(data.error_list);
      }
    },
    showConfirm(data) {
      let $this = this
      if ('error_list' in data) {
        this.$dialog
        .confirm(data['error_list'][0], {okText: 'Продолжить', cancelText: 'Отмена'})
        .then(function() {
          $this.save(true);
        })
        .catch(function() {
        })
      }
    },
    saveLocal() {
      for (let index in this.save_local) {
        localStorage.setItem(this.$options.name + '_' + this.save_local[index].field, this.instance[this.save_local[index].field])
      }
    },
    beforeAccept(){},
    loadLocal() {
      for (let index in this.save_local) {
        let varib = this.$options.name + '_' + this.save_local[index].field
        // eslint-disable-next-line no-prototype-builtins
        if (localStorage.hasOwnProperty(varib)) {
          let value = this.save_local[index].type(localStorage.getItem(varib))
          if (this.save_local[index].type === Boolean) {
            if (localStorage.getItem(varib) === 'true') {
              value = true
            } else {
              value = false
            }
          }
          if (value !== 'undefined' && value !== null)
            this.$set(this.instance, this.save_local[index].field, value)
        }
      }
    },
    prepareSaveRequest(url) {
      return this.method === 'create' ? axios.post(url, this.instance) : axios.put(url, this.instance)
    },
    save() {
      if (!this.loading_saving) {
        this.$refs.observer.validate().then(valid => {
          if (valid) {
            this.beforeAccept()
            this.loading_saving = true
            let message_success = this.method === 'create' ? 'Объект создан' : 'Объект обновлен';
            let message_error = this.method === 'create' ? 'Ошибка создания объекта' : 'Ошибка обновления объекта';
            let url = this.method === 'create' ? this.getUrl(this.url_list) : this.getUrl(this.url_detail)
            let request = this.prepareSaveRequest(url)
            request.then(response => {
              //this.saveLocal()
              this.loading_saving = false
              this.method === 'create' ? this.$emit('add', response.data.data) : this.$emit('reload', response.data.data)
              this.$emit('success')
              this.showNotify('success', message_success)
              this.closeModal()
            }).catch((e) => {
              this.except(e, message_error)
              this.loading_saving = false
            })
          }
        })
      }
    },
    closeModal() {
      this.$refs.modal.close()
      this.$nextTick(()=>{
        this.resetForm()
      })
    },
    afterShow() {},
    showModalCreate(params) {
      this.method = 'create'
      this.$refs.modal.show()
      this.afterShow()
      if (typeof params !== 'undefined' && params !== null) {
        for (let param in params) {
          this.$set(this.instance, param, params[param])
        }
        this.loadLocal()
      }
    },
    showModalCreateFromCopy(params) {
      this.method = 'create'
      this.$refs.modal.show()
      this.getDetail().then(()=> {
        if (typeof params !== 'undefined' && params !== null) {
          for (let param in params) {
            this.$set(this.instance, param, params[param])
          }
        }
        this.instance.id = null
      }).catch(()=>{})
      this.afterShow()
    },
    showModalEdit(params, extra) {
      this.method = 'edit'
      this.$refs.modal.show()
      this.getDetail().then(()=> {
        if (typeof params !== 'undefined' && params !== null) {
          for (let param in params) {
            this.$set(this.instance, param, params[param])
          }
        }
      }).catch((e)=>{console.log(e)})

      if (typeof extra !== 'undefined' && extra !== null) {
        for (let extra_param in extra) {
          this.$set(this.extra, extra_param, extra[extra_param])
        }
      }

      this.afterShow()
    },
    showModalEditLocal(params) {
      this.method = 'edit'
      this.$refs.modal.show()
      this.afterShow()
      if (typeof params !== 'undefined' && params !== null) {
        for (let param in params) {
          this.instance[param] = params[param]
        }
      }
    }
  }
}
