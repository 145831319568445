import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from './store'
import * as credintals from './credintals'
import Datatable from './components/Plugins/vue2-datatable'
import Notifications from 'vue-notification'
import locale from './locale/main_table/ru-RU'
import VueNativeSock from 'vue-native-websocket'
import {URL_WEBSOCKET} from './config'
//import VeeValidate, { Validator } from 'vee-validate';
//import ru from 'vee-validate/dist/locale/ru';
import VueSelect from 'vue-cool-select'
import VueImg from 'v-img';
import { Settings } from 'luxon'
import VueTippy from 'vue-tippy'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import VueMask from 'v-mask'
import VueProgressBar from 'vue-progressbar'


Settings.defaultLocale = 'ru'
const options = {
  color: '#66bb6a',
  failedColor: '#FF0000',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  height: '2px',
  autoRevert: false,
  location: 'top',
  inverse: false
}

Vue.use(VueMask)
Vue.use(VueTippy)
Vue.use(VueProgressBar, options)
Vue.use(VueImg)
Vue.use(VueNativeSock, URL_WEBSOCKET + 'realtime/?token=' + store.getters.token, {store: store,
                                                                                  format: 'json',
                                                                                  connectManually: true,
                                                                                  /*reconnection: true,
                                                                                  reconnectionAttempts: 5,
                                                                                  reconnectionDelay: 3000,*/})
Vue.use(Vuex)
Vue.use(VueSelect, { theme: 'bootstrap'})
Vue.use(Notifications)
Vue.use(Datatable, {locale})
Vue.config.productionTip = false
Vue.prototype.$api = credintals

//Vue.use(VeeValidate)
//Validator.localize('ru', ru);


Vue.mixin({
  data () {
    return {
      error_network: false
    }
  },
  methods: {
    base64toBlob(base64Data, contentType) {
      contentType = contentType || '';
      let sliceSize = 1024;
      let byteCharacters = atob(base64Data);
      let bytesLength = byteCharacters.length;
      let slicesCount = Math.ceil(bytesLength / sliceSize);
      let byteArrays = new Array(slicesCount);
      for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);
        let bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
      }
      return new Blob(byteArrays, { type: contentType });
    },
    getFileName(contentDisposition) {
      let fileName = 'file';
      if (contentDisposition) {
        try {
          const fileNameMatch = contentDisposition.split('filename=')[1].split(';');
          fileName = fileNameMatch[0];
        } catch (e) {
          console.log(e)
        }
      }
      return fileName
    },
    reload() {
      this.error_network = false
      location.reload()
    },
    toggleMenu () {
      const body = document.body
      body.classList.toggle('sidebar-mini')
    },
    encodeQueryData (query) {
      let ret = []
      for (let d in query) {
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(query[d]))
      }
      return ret.join('&')
    },
    showNotify: function (type, message, duration) {
      if (!duration)
        duration = 10000
      this.$notify({
        group: 'notify',
        type: type,
        text: message,
        duration: duration,
        speed: 1000,
      })
    },
    showNotifyReport: function (type, message, file, action) {
      this.$notify({
        group: 'reports',
        type: type,
        text: message,
        duration: -1,
        speed: 1000,
        file: file,
        action: action
      })
    },
    showError: function (response, defaultError) {
      if (response.status !== 401) {
        try {
          let error = ''
          if ('error_list' in response.data) {
            if (typeof(response.data['error_list'][0]) === "object") {
              error = Object.values(response.data['error_list'][0])[0][0]
            } else {
              error = response.data['error_list'][0]
            }
            if (typeof (error) == 'undefined') {
              error = Object.values(response.data['error_list'])[0][0]
            }
            this.showNotify('error', error)
            return error
          } else {
            this.showNotify('error', defaultError)
            return defaultError
          }
        } catch (e) {
          this.showNotify('error', defaultError)
          return defaultError
        }
      }
    }
  }
})

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
