<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-row align-items-center mr-2">
        <button @click="$refs.news_modal.showModalCreate()" href="#" class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm">
          Создать
        </button>
        <button :disabled="!current_new" @click="$refs.news_modal.showModalEdit()" href="#" class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm">
          Редактировать
        </button>
        <button title="Отправка push уведомлений пользователям" :disabled="!current_new || loading_action_save || current_new.status === 2 " @click="publicUnpublicPost(2)" href="#" class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm">
          Опубликовать
        </button>
        <button title="Новость останется но не будет доступна для просмотра пользователям" :disabled="!current_new || loading_action_save || current_new.status === 1 " @click="publicUnpublicPost(1)" href="#" class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm">
          Снять с публикации
        </button>
        <button title="Новость будет удалена безвозвратно" :disabled="!current_new" @click="remove(news, current_new); current_new=null" href="#" class="d-none d-sm-inline-block btn btn-sm btn-danger shadow-sm">
          Удалить
        </button>
        <div class="ml-auto">
          <button @click="getNews(news.query)" class="btn btn-success btn-round btn-sm btn-fab btn-fab-sm"><i class="fa fa-sync"></i></button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <datatable ref='news' id="news" class="mt-3 table-pointer" v-bind="$data.news"/>
    </div>
    <news-modal :url_list="url_list" :url_detail="url_detail" @add="addRow(news.data, $event)" @reload="reloadRow(news.data, $event.id, $event)" ref="news_modal"></news-modal>
  </div>
</template>

<script>
import axios from 'axios'
import urls from './urls'
import NewsModal from "@/components/News/NewsModal";
import DataTableMixin from "@/components/Mixins/DataTableMixin";
import TabMixin from "@/components/Mixins/TabMixin";

export default {
  props: ['tab'],
  name: "news-index",
  components: {NewsModal},
  mixins: [DataTableMixin, TabMixin],
  data() {
    return {
      url_list: () => urls.api_list_posts(),
      url_detail: () => urls.api_post_detail(this.current_new.id),
      current_new: null,
      loading_houses: true,
      loading_news: false,
      loading_post: false,
      saving_post: false,
      loading_action_save: false,
      news:{
        name: 'news',
        data: [],
        total: 0,
        query: {},
        HeaderSettings: false,
        is_visible_modal_post: false,
        is_visible_modal_update: false,
        tblClass: 'table-bordered apartments',
        clickSignal: 'click_on_new_',
        dblClickSignal: 'dblclick_post_',
        contextSignal: 'show_context_post_',
        columns: [
          { title: 'ID', visible: false, field: 'id'},
          { title: 'Заголовок', field: 'title', thClass: 'text-center align-middle', tdClass: 'text-left p-1 align-middle'},
          { title: 'Описание', field: 'description', thClass: 'text-center align-middle', tdClass: 'text-left p-1 align-middle'},
          { title: 'Область действия', field: 'purpose_serialized',},
          { title: 'Дата', field: 'date_serialized', thClass: 'text-center align-middle', tdClass: 'text-center p-1 align-middle'},
          { title: 'Статус', field: 'status_serialized', thClass: 'text-center align-middle', tdClass: 'text-center p-1 align-middle'},
        ],
      }
    }
  },
  watch: {
    'news.query': {
      handler (query) {
        this.getNews(query)
      },
      deep: true
    }
  },
  mounted () {
    this.news.clickSignal = this.news.clickSignal + this.tab
    this.$root.$on(this.news.clickSignal, this.clickOnRow)
    this.$root.$on('public_unpublic_post', this.publicUnpublicPost)
  },
  beforeDestroy () {
    this.$root.$off('public_unpublic_post', this.publicUnpublicPost)
    this.$root.$off(this.news.clickSignal, this.clickOnRow)
  },
  methods: {
    publicUnpublicPost(status) {
      this.loading_action_save = true
      let post_id = this.current_new['id']
      axios.put(urls.api_post_public_unpublic(post_id), {'status': status})
      .then(response => {
        this.reloadRow(this.news.data, post_id, response.data.data)
        this.clickOnRow(response.data.data)
        this.loading_action_save = false
        if (status === 1)
          this.showNotify('success', 'Новость снята с публикации')
        else
          this.showNotify('success', 'Новость опубликована')
      })
      .catch((e) => {
        this.showError(e, 'Ошибка обновления новости')
        this.loading_action_save = false
      })
    },
    getNews(query) {
      this.getDataTable(this.url_list() + '?' + this.encodeQueryData(query), this.news, false)
    },
    clickOnRow (item) {
      this.current_new = item
    },
 }
}
</script>
