import Vuex from 'vuex'
import Vue from 'vue'
import * as types from './mutation-types'
import createPersistedState from 'vuex-persistedstate'
import router from '../router'
import axios from 'axios'
import {HOST, URL_WEBSOCKET} from '../config'
let refreshTimeout = null;

let jwtDecode = require('jwt-decode')
Vue.use(Vuex)
const vm = new Vue()
const state = {
  user: {},
  token: null,
  refresh: null,
  login_error: null,
  pk: null,
  socket: {
    isConnected: false,
    message: '',
    reconnectError: false,
  }
}

const mutations = {
  [types.LOGIN]: (state, payload) => {
    state.token = payload.token
    state.refresh = payload.refresh
    state.user = payload.user
    //store.dispatch('connectToSocket')
    store.dispatch('initSession')
    router.push(payload.redirect)
  },
  [types.LOGIN_ERROR]: (state, error) => {
    state.login_error = error
  },
  [types.LOGOUT]: (state) => {
    state.token = null
    state.refresh = null
    state.pk = null
    state.user = {}
    state.socket.isConnected = false
    state.socket.channel_name = ''
    //store.dispatch('disconnectToSocket')
    clearTimeout(refreshTimeout);
    refreshTimeout = null;
    router.push({name: 'login'})
  },
  [types.TOKEN_REFRESHED]: (state, payload) => {
    state.token = payload.access
    state.refresh = payload.refresh
    state.user = payload.user
  },
  SOCKET_ONOPEN (state, event) {
    Vue.prototype.$socket = event.currentTarget
    state.socket.isConnected = true
  },
  SOCKET_ONCLOSE (state) {
    state.socket.isConnected = false
    state.socket.channel_name = ''
    //let $this = this
    //if (state.token != null) {
    //  setTimeout(function() { $this.dispatch('reconnectToSocket') }, 3000);
    //}
  },
  SOCKET_ONERROR (state, event) {
    state.socket.isConnected = false
    state.socket.channel_name = ''
    console.error(state, event)
  },
  SOCKET_ONMESSAGE (state, message) {
    state.socket.message = message
    if (message.handle === 'inner') {
      state.socket.channel_name = message.data.channel_name
    } else if (message.handle === 'logout') {
      router.push('logout')
    } else {
      vm.$root.$emit(message.handle, message)
    }
  },
  SOCKET_RECONNECT (state) {
    state.socket.isConnected = false
    state.socket.channel_name = ''
  },
  SOCKET_RECONNECT_ERROR (state) {
    state.socket.isConnected = false
    state.socket.channel_name = ''
    state.socket.reconnectError = true
  }
}

const actions = {
  disconnectToSocket () {
    if (this.state.socket.isConnected) {
      vm.$disconnect()
    }
  },
  reconnectToSocket () {
    if (this.state.token)
      vm.$connect(URL_WEBSOCKET + 'realtime/?token=' + this.state.token, {store: store, format: 'json'})
  },
  connectToSocket () {
    if (this.state.token)
      vm.$connect(URL_WEBSOCKET + 'realtime/?token=' + this.state.token, {store: store, format: 'json'})
  },
  async refreshToken () {
    await axios.post(HOST + 'api/backend/refresh/', { refresh: this.state.refresh })
      .then((response) => {
        this.commit(types.TOKEN_REFRESHED, response.data)
        vm.$disconnect()
        const tokenExpiry = jwtDecode(this.state.token).exp - (Date.now() / 1000)
        clearTimeout(refreshTimeout);
        let $this = this
        refreshTimeout = setTimeout(function() {$this.dispatch('refreshToken')}, (tokenExpiry - 600) * 1000);
      })
      .catch(() => {
        this.commit(types.LOGOUT, {redirect: '/login'})
      })
  },
  initSession() {
    try {
      if (this.state.token) {
        const tokenExpiry = jwtDecode(this.state.token).exp - (Date.now() / 1000)
        if (tokenExpiry < 600) {
          this.commit(types.LOGOUT, {redirect: '/login'})
        } else {
          let $this = this
          refreshTimeout = setTimeout(function() { $this.dispatch('refreshToken') }, (tokenExpiry - 600) * 1000);
        }
      } else {
        this.commit(types.LOGOUT, {redirect: '/login'})
      }
    } catch(e) {
      this.commit(types.LOGOUT, {redirect: '/login'})
    }
  }
}

const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  actions: actions,
  plugins: [
    createPersistedState()
  ],
  getters: {
    token: state => {
      return state.token
    },
    isConnected: state => {
      return state.socket.isConnected
    }
  }
})

export default store
