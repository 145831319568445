<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex flex-row align-items-center mr-2">
        <div>
          <a @click="addCategory" href="#" class="d-none d-sm-inline-block btn btn-sm btn-success shadow-sm">
            Добавить категорию
          </a>
        </div>
        <div class="ml-2">
          <label>Подразделение</label>
          <main-id-select :select_first="true" :style="{'min-width': '200px'}" ref="main_id_select" v-bind:value="filters.main_id" v-on:changed="filters.main_id=$event.id"></main-id-select>
        </div>
      </div>
    </div>
    <div class="col-6 mt-4 mb-4" :key="info.id" v-for="(info, categoryIndex) in infos">
      <div class="card">
        <div class="card-header card-header-text card-header-white">
          <div class="card-text" style="width:50%">
            <input placeholder="Заголовок" class="form-control"  style="-webkit-input-placeholder:white; -moz-placeholder:white" v-model="info.title"/>
            <main-id-select :placeholder="'Подразделение'" :exclude_first_item="true" v-bind:value="info.main_id" v-on:changed="$set(info, 'main_id', $event.id);"/>
          </div>
        </div>
        <div class="card-body">
          <div :key="element.id" class="mt-2" v-for="(element, idx) in info.info_fields">
            <div class="d-flex flex-row bd-highlight">
              <input style="margin-top:5px" placeholder="Поле" type="text" class="form-control bd-highlight" v-model="element.title" />
              <input style="margin-top:5px" placeholder="Значение" type="text" class="ml-3 form-control bd-highlight mr-3" v-model="element.value" />
              <select class="form-control" v-model="element.schema">
                <option value="0">Тип не выбран</option>
                <option value="1">Телефон</option>
                <option value="2">Email</option>
                <option value="3">Ссылка</option>
              </select>
              <i @click="removeFieldFromServer(info, idx)" class="mt-1 ml-3 cursor-pointer fa fa-times"></i>
            </div>
          </div>
          <i @click="addField(info)" class="cursor-pointer fa fa-plus mt-3" style="font-size: 15px"></i>
          <div class="mt-2">
            <span class="text-danger">{{info.error}}</span>
          </div>
        </div>
        <div class="justify-content-between card-footer">
          <button class="btn btn-danger btn-sm" @click="removeInfo(info, categoryIndex)">Удалить</button>
          <button class="btn btn-success btn-sm" @click="saveInfo(info, categoryIndex)">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import urls from './urls'
import MainIdSelect from "@/components/Plugins/selects/main-ids-select";
export default {
  components: {MainIdSelect},
  props: ['tab'],
  name: "info-index",
  data: () => ({
    filters: {
      main_id: null
    },
    infos: [],
    infos_local: [],
    save_info: false,
    loading_infos: true,
    loading_fields: true
  }),
  watch: {
    filters: {
      handler: function () {
        this.getInfos()
      },
      deep: true
    },
  },
  mounted () {
    this.$root.$on('close_tab_' + this.tab, this.closeTab)
    //this.getInfos()
  },
  beforeDestroy () {
    this.$root.$off('close_tab_' + this.tab, this.closeTab)
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    saveInfo (info, index) {
      if(!info.main_id) {
        this.$set(info, 'error', 'Укажите подразделение')
        return
      }
      if(info.title.length === 0) {
        this.$set(info, 'error', 'Укажите заголовок')
        return
      }
      info.error = ''
      this.save_info = true
      if (info.id) {
        axios.put(urls.api_info_detail(info.id), info)
        .then(() => {
          this.save_info = false
          this.showNotify('success', 'Категория обновлена')
        })
        .catch((e) => {
          this.showError(e, 'Ошибка обновление категорий')
          this.save_info = false
        })
      } else {
        axios.post(urls.api_list_infos(), info)
        .then(response => {
          this.save_info = false
          info.id = response.data.data.id
          this.showNotify('success', 'Категория создана')
        })
        .catch((e) => {
          this.showError(e, 'Ошибка создания категорий')
          this.save_info = false
        })
      }
    },
    getInfos () {
      this.loading_infos = true
      axios.get(urls.api_list_infos() + `?main_id=${this.filters.main_id}`)
        .then(response => {
          this.infos  = response.data.data
          this.loading_infos = false
        })
        .catch((e) => {
          this.showError(e, 'Ошибка получения категорий')
          this.loading_infos = false
        })

    },
    removeFieldFromServer (info, index) {
      if (index !== 0)
        info.info_fields.splice(index, 1);
    },
    removeInfo (info, index) {
      this.save_info = true
      if (info.id) {
        axios.delete(urls.api_info_detail(info.id), info)
        .then(() => {
          this.save_info = false
          this.showNotify('success', 'Категория удалена')
          this.infos.splice(index, 1);
        })
        .catch((e) => {
          this.showError(e, 'Ошибка обновление категорий')
          this.save_info = false
        })
      } else {
        this.save_info = false
        this.infos.splice(index, 1);
      }
    },
    addField (info) {
      info.info_fields.push({title: '', value: '', schema: 0});
    },
    addCategory () {
      this.infos.push({main_id: this.filters.main_id, title: '', info_fields: [{title: '', value: '', schema: 0}]})
    },
    closeTab() {
      this.$destroy()
    }
  }
}
</script>
