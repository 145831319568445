<template>
  <div name="Datatable" class="mh-datatable">
    <div v-if="$slots.default || HeaderSettings" class="clearfix" style="margin-bottom: 10px">
      <header-settings v-if="HeaderSettings" class="pull-right"
        :columns="columns" :support-backup="supportBackup">
      </header-settings>
      <slot />
    </div>
    <tbl ref="tbl" v-bind="$props" />
      <div v-if="Pagination" class="container row" style="margin-top: 10px">
        <div class="col-sm-6" style="white-space: nowrap">
          <strong>
            {{ $i18nForDatatable('Total') }} {{ total }} {{ $i18nForDatatable(',') }}
          </strong>
          <page-size-select :query="query" :page-size-options="pageSizeOptions" />
        </div>
        <div class="col-sm-6">
          <pagination class="pull-right" :total="total" :query="query" />
        </div>
    </div>
  </div>
</template>
<script>
import HeaderSettings from './HeaderSettings/index.vue'
import Tbl from './Table/index.vue'
import Pagination from './Pagination.vue'
import PageSizeSelect from './PageSizeSelect.vue'
import props from './_mixins/props'
import keyGen from './_utils/keyGen'
import { stringify, saveToLS, getFromLS } from './_utils/localstorage'

export default {
  name: 'Datatable',
  mixins: [props],
  components: { HeaderSettings, Tbl, Pagination, PageSizeSelect },
  methods: {
    up() {
      this.$refs.tbl.up()
    },
    down() {
      this.$refs.tbl.down()
    },
    enter() {
      this.$refs.tbl.enter()
    },
    setFirstSelected() {
      this.$refs.tbl.setFirstSelected()
    },
    select(id) {
      this.$refs.tbl.select(id)
    },
  },
  created() {

    // init query (make all the properties observable by using `$set`)
    let q = null;

    if(this.supportBackup === true && this.gridName != null && this.gridName != ""){
      q = getFromLS(this.storageKey)
    }

    if (!q) {
      q = { limit: 20, offset: 0, sort: [], filters: [], ...this.query }
    } 

    Object.keys(q).forEach(key => { this.$set(this.query, key, q[key]) })

    // if(this.dataSource != null && this.dataSource.read != null){
    //     this.$http.get(this.dataSource.read).then((response) {
    //       this.data = response.data;
    //     });
    // }

  },
  updated() {
    this.isFirstInit = false;
  },
  data() {
    return {
      isFirstInit: true,
      storageKey: this.supportBackup === true ? keyGen(stringify(this.columns)) + "-" + this.gridName : ''
    }
  },
  watch: {
    query: {
      //immediate: true,
      handler () {
        if(this.supportBackup && !this.isFirstInit && this.gridName != null && this.gridName != ""){
          saveToLS(this.storageKey, this.query);
        }
      },
      deep: true
    },
    data: {
      handler (data) {
        const { supportNested } = this
        // support nested components feature with extra magic
        if (supportNested) {
          const MAGIC_FIELD = '__nested__'
          data.forEach(item => {
            if (!item[MAGIC_FIELD]) {
              this.$set(item, MAGIC_FIELD, {
                comp: undefined, // current nested component
                visible: false,
                $toggle (comp, visible) {
                  switch (arguments.length) {
                    case 0:
                      this.visible = !this.visible
                      break
                    case 1:
                      switch (typeof comp) {
                        case 'boolean':
                          this.visible = comp
                          break
                        case 'string':
                        case 'object':
                          this.comp = comp
                          this.visible = !this.visible
                          break
                      }
                      break
                    case 2:
                      this.comp = comp
                      this.visible = visible
                      break
                  }
                }
              })
              if (supportNested === 'accordion') {
                this.$watch(
                  () => item[MAGIC_FIELD],
                  nested => {
                    // only one nested component can be expanded at the same time
                    if (data.filter(item => item[MAGIC_FIELD].visible).length < 2) return

                    data.forEach(item => {
                      if (item[MAGIC_FIELD].visible && item[MAGIC_FIELD] !== nested) {
                        item[MAGIC_FIELD].visible = false
                      }
                    })
                  },
                  { deep: true }
                )
              }
              Object.defineProperty(item, MAGIC_FIELD, { enumerable: false })
            }
          })
        }
      },
      immediate: true
    }
  }
}
</script>
