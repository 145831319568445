export default {
  /* HeaderSettings/index.vue */
  'Apply': 'Применить',
  'Apply and backup settings to local': 'Применить и сохранить настройки локально',
  'Clear local settings backup and restore': 'Очистить локальные настройки',
  'Using local settings': 'Использовать локальные настройки',

  /* Table/TableBody.vue */
  'No Data': 'Нет данных',

  /* index.vue */
  'Total': 'всего',
  ',': ', показать',

  /* PageSizeSelect.vue */
  'items / page': ' '
}
