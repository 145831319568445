<template>
  <span>
    <notifications width="500" group="notify">
      <template slot="body" slot-scope="props">
        <div :class="'vue-notification ' + props.item.type">
          <a class="title">
            {{props.item.title}}
          </a>
          <a class="close" @click="props.close">
            <i class="fas fa-times"></i>
          </a>
          <div style="font-size: 14px" v-html="props.item.text"></div>
        </div>
      </template>
    </notifications>
    <notifications width="300" group="reports"
                   position="top center">
       <template slot="body" slot-scope="props">
        <div :class="'vue-notification ' + props.item.type" style="text-align: center">
            <a class="title">
              {{props.item.title}}
            </a>
            <a class="close" @click="props.close">
              <i class="fas fa-times"></i>
            </a>
            <div style="font-size: 14px" v-html="props.item.text.text"></div>
            <div class="mt-2" style="color: white;font-size: 14px; cursor: pointer"
                 @click="download(props.item.text.file)">Скачать</div>
        </div>
      </template>
    </notifications>
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </span>
</template>
<script>
export default {
  name: 'App',
  data: () => ({
    notices: []
  }),
  computed: {
    token () {
      return this.$store.state.token
    }
  },
  mounted: function () {
    this.$options.sockets.onmessage = (data) => {
      let result = JSON.parse(data.data)
      this.$root.$emit(result.handle, result)
    }
    //this.$store.dispatch('connectToSocket')
    this.$store.dispatch('initSession')
    this.$root.$on('voting_protocol_ready', this.votingProtocolReady)
    this.$root.$on('voting_protocol_error', this.votingProtocolError)
    this.$root.$on('notify_error', this.notifyError)
    this.$root.$on('notify_success', this.notifySuccess)
  },
  beforeDestroy: function () {
    delete this.$options.sockets.onmessage
    this.$root.$off('voting_protocol_ready', this.votingProtocolReady)
    this.$root.$off('voting_protocol_error', this.votingProtocolError)
    this.$root.$off('notify_error', this.notifyError)
    this.$root.$off('notify_success', this.notifySuccess)
  },
  methods: {
    download (fileName) {
      window.open(fileName, "_blank")
    },
    notifyError (data) {
      this.showNotify('error', data.data.text, -1)
    },
    notifySuccess (data) {
      this.showNotify('success', data.data.text, -1)
    },
    votingProtocolReady (data) {
      this.showNotifyReport('success', {text: 'Протокол готов', file: data.data.inner_filename, action: 'voting_protocol'}, -1)
    },
    votingProtocolError (data) {
      this.showError(data, 'Ошибка во время генерации протокола')
    }
  }
}
</script>
<style>
#app {
 font-family: 'Comfortaa', cursive !important;
}
</style>
