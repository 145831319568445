import {HOST} from '../../config.js'
const URI_ENTER_LOGIN = 'api/backend/manager/login/'
const URI_LOGOUT = 'api/backend/logout/'
export default {
  api_logout: function () {
    return HOST + URI_LOGOUT
  },
  api_validate_login: function () {
    return HOST + URI_ENTER_LOGIN
  },
}
