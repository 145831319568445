import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import ru from 'vee-validate/dist/locale/ru.json'
import { localize } from 'vee-validate';
localize({
  ru,
});

extend("required", {
  ...required,
  message: ru.messages.required
});
extend('notSelected', value => {
  if (!!value && value !== -1 && value !== '-1') {
    return true;
  }
  return ru.messages.required
});