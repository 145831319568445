<template>
  <date-picker @click.native="clicked"
               :type=type
               :clearable='false'
               @mousedown.native="mousedown"
               ref="datepicker"
               :open.sync="open"
               @change="change"
               v-model="selected"
               :show-time-panel="showTimePanel"
               :input-class="'form-control'"
               :time-title-format="'DD.MM.YYYY'"
               :popup-style="{'z-index': 10001}"
               :format=format
               valueType="format">
    <template v-if="type==='datetime'" v-slot:footer>
      <button class="mx-btn" style="border: 0" @click="mousedown">
        Закрыть
      </button>
      <button class="mx-btn" style="border: 0" @click="toggleTimePanel">
        {{ showTimePanel ? 'выбрать дату' : 'выбрать время' }}
      </button>
    </template>
    <template v-slot:input>
      <input :placeholder="placeholder" :disabled="clickable" @blur="blur" v-on:keydown="keydown" @input="input" :class="{'disabled-input': clickable}"  style="" class="form-control" v-model="selected" v-mask=mask>
    </template>
  </date-picker>
</template>

<script>
import DatePicker from 'vue2-datepicker'
export default {
  name: "calendar",
  components: {DatePicker},
  props: {
    placeholder: {},
    after_complite: {default: true},
    value: {},
    type: {default: 'date'},
    format: {default: 'DD.MM.YYYY'},
    mask: {default: '##.##.####'},
    clickable: {default: false}
  },
  data: ()=>({
    showTimePanel: false,
    last_key: null,
    selected: null,
    open: false,
  }),
  mounted() {
    this.initial()
  },
  watch: {
    value: {
      handler: function () {
        this.initial()
      },
    },
  },
  methods: {
    handleOpenChange() {
      this.showTimePanel = false;
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    mousedown(){
      this.open = false;
    },
    clicked(event) {
      if (!this.clickable) {
        this.open = event.target.tagName !== 'INPUT';
      } else {
        this.open = !this.open
      }
    },
    initial() {
      this.$nextTick(()=> {
        this.selected = this.value
      })
    },
    keydown(key) {
      this.$refs.datepicker.handleInputKeydown(key)
      this.last_key = key.which
    },
    change(value, type) {
      this.selected = value
      this.$emit('changed', value)
      if (type === 'minute') {
        this.mousedown()
      }
    },
    blur() {
      /*if (this.after_complite) {
        if(this.selected && this.selected.length !== 10) {
          if (this.selected.length === 1) {
            this.selected = '0' + this.selected
          }
          if (this.selected.length === 2) {
           this.selected = this.selected + '.' + moment().format('MM') + '.' + moment().format('YYYY')
          }
          if (this.selected.length === 3) {
           this.selected = this.selected + moment().format('MM') + '.' + moment().format('YYYY')
          }
          if (this.selected.length === 4) {
            this.selected = this.selected.slice(0, 3) + '0' + this.selected[3]
          }
          if (this.selected.length === 5) {
           this.selected = this.selected + '.' + moment().format('YYYY')
          }
          if (this.selected.length === 6) {
           this.selected = this.selected + moment().format('YYYY')
          } else {
            if (this.selected.length > 6 && this.selected.length !== 10) {
              this.selected = this.selected.slice(0, 5) + '.' + moment().format('YYYY')
            }
          }
          this.$emit('changed', this.selected)
        }
      }*/
    },
    input() {
      this.$emit('changed', this.selected)
    }
  }
}
</script>
