import Multiselect from 'vue-multiselect'
import axios from 'axios/index'
export default {
  name: 'select-base',
  components: {Multiselect},
  props: {
    value: {},
    internal_search: {default: true},
    show_clear: {default: false},
    select_all: {default: false},
    disabled: {default: false},
    get_on_mount: {default: true},
    // вызывать событие changed в функции initial
    change_on_initial: {default: true},
    group_select: {default: false},
    select_first: {
      default: false
    },
    exclude_first_item: {
      type: Boolean,
      default: false
    },
    just_from_init: {default: false},
    close_on_select: {default: true},
    initial_items: {},
    placeholder: {
      type: String,
      default: ''
    },
    params: {},
    first_item_name: {
      type: String,
      default: 'Все'
    },
    first_item_id: {
      default: '-1'
    },
    multiple: {type: Boolean, default: false},
    hide_selected: {type: Boolean, default: false},
    allow_empty: {type: Boolean, default: false},
    // доступность локального поиска
    searchable: {type: Boolean, default: true},
    // доступность серверного поиска
    server_searchable: {type: Boolean, default: true},

  },
  data: () => ({
    loading: false,
    options: [],
    selected: null,
    url: null,
    url_search: null,
    error_message: 'Ошибка получения данных',
    // текст внутри select
    input_value: ''
  }),
  watch: {
    value: {
      handler: function () {
        this.initial()
      },
    },
  },
  mounted() {
   // если есть переданные данные для иницилизации
   if (this.initial_items !== null && typeof this.initial_items !== 'undefined' && this.initial_items[0] !== null && typeof this.initial_items[0] !== 'undefined') {
      // то делаем эти данные опциями
      this.options = this.initial_items
      // а после проиницилизируем, что б данные опции были выбраны в select
      this.initial()
      // но после все равно попробуем запросить опции с сервера
      if (!this.just_from_init)
        if (this.get_on_mount)
          this.getOptionsByQuery()
      else
        this.selectFirst()
   } else {
     if (this.get_on_mount)
       this.getOptionsByQuery()
   }
   if (this.$props.placeholder !== null && typeof this.$props.placeholder !== 'undefined') {
      this.placeholder = this.$props.placeholder
   }
   if (this.url_search !== null && this.params)
      this.url_search += '?' + this.encodeQueryData(this.params) + '&search='

  },
  methods: {
    selectAll() {
      this.selected = []
      let $this = this
      this.options.forEach(function (item) {
        $this.selected.unshift(item)
      })
      let values = []
      this.selected.forEach(function(item){
         values.unshift(item.id)
      });
      this.$emit('changed', values)
    },
    focus() {
      this.$refs.select.$el.focus()
    },
    setNull() {
      this.selected = {id: null}
      this.$emit('changed', this.selected)
    },
    clear() {
      this.options = []
      this.selected = {id: null}
      this.$emit('changed', this.selected)
    },
    addTag (newTag) {
      const tag = {
        title: newTag,
        id: newTag
      }
      this.options.push(tag)
      this.$nextTick(()=> {
        this.selected = tag
        this.change()
      })
    },
    getOptionsByQuery(params=null) {
      if (this.url !== null && typeof this.url !== 'undefined') {
        if (params) {
          this.url = this.$options.data().url + '?' + this.encodeQueryData(params)
        } else {
          if (this.params) {
            this.url += '?' + this.encodeQueryData(this.params)
          }
        }
        this.getOptions()
      }
    },
    async search(search) {
      if (this.server_searchable === true) {
        // получение опций данного селекта при асинхроном поиске
        if (this.url_search !== null && search.length > 0) {
          this.loading = true
            axios.get(this.url_search + search).then((response) => {
              this.options = response.data.data//.push(...response.data.data)
              if (this.selected && this.selected.length > 0) {
                this.options.push(...this.selected)
              }
              this.options = [...new Map(this.options.map(obj => [JSON.stringify(obj), obj])).values()];
              if (this.exclude_first_item === false) {
                let item = {id: this.first_item_id}
                item[this.$refs.select.label] = this.first_item_name
                this.options.unshift(item)
              }
              this.loading = false
            })
        }
      }
    },
    selectFirst() {
      if (this.select_first) {
          this.$nextTick(()=>{
            this.selected = this.exclude_first_item === true ? this.options[0] : this.options[1]
            this.$emit('changed', this.selected)
          })
        }
    },
    getOptions() {
      // получение статичных опций данного селекта
      this.loading = true
      axios.get(this.url).then((response) => {
        this.options = response.data.data
        if (this.exclude_first_item === false) {
          let item = {id: this.first_item_id}
          item[this.$refs.select.label] = this.first_item_name
          this.options.unshift(item)
          this.initial()
        }
        this.selectFirst()
        this.loading = false
        this.initial()
      }).catch(() => {
        this.loading = false
      })
    },
    input(event) {
      this.input_value = event.target.value
    },
    change() {
      if (this.selected.id === -1 || this.selected.id === '-1') {
        this.$emit('changed', {id: ''})
      } else {
        if (this.multiple){
          let values = []
           this.selected.forEach(function(item){
             values.unshift(item.id)
           });
           this.$emit('changed', values)
        }
        else {
          this.$emit('changed', this.selected)
        }
      }
    },
    initial() {
      // функция которая устанавливает выбранные данные, вызывается когда смотирован данный select, а так же при изменении value
      if (this.value !== null && typeof this.value !== "undefined") {
        let $this = this
        // при множественном выборе
        if(this.multiple) {

          this.selected = []
          // сначала ищим по опциям которые прогружены
          this.value.forEach(function (value_item) {
            let finded = false
            $this.options.forEach(function (item) {
              if (item.id === value_item) {
                $this.selected.unshift(item)
                finded = true
               }
            })
            // если не нашли так же поищим по данным которыми иницилизировали данный select
            if (!finded && $this.initial_items) {
              $this.initial_items.forEach(function (item) {
                if (item.id === value_item) {
                  $this.selected.unshift(item)
                 }
              })
            }
          })
        // если одиночный выбор, просто из опций выберем тот который передали в value
        } else {
          this.options.forEach(function (item) {
            if (item.id === $this.value && $this.selected !== item) {
              $this.selected = item
              if($this.change_on_initial) {
                $this.change()
              }
            }
          })
        }
        this.$emit('initialed', this.selected)
      }
    },
  }
}
