var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.useComplexMode)?_c('div',{directives:[{name:"resize",rawName:"v-resize:debounce",value:(_vm.handleResize),expression:"handleResize",arg:"debounce"}],ref:"table",staticClass:"-complex-table",attrs:{"name":"ComplexTable"}},[_vm._l((['Header', 'Body']),function(x,index){return [((x !== 'Footer' || x === 'Footer') && (_vm.pending == false || x == 'Header' ))?_c('div',{key:index,ref:"wrappers",refInFor:true,class:("-table-" + (x.toLowerCase())),style:([
        x === 'Body' && { height: (_vm.calcTableHeight + "px") }
      ]),attrs:{"name":("Table" + x + "Wrapper")}},[_c('div',{ref:("NormalTable" + x),refInFor:true,style:([ { 
                    width: ((_vm.mainTableWidth - _vm.verticalScrollWidthCorrection) + "px"), 
                    marginLeft:  (_vm.fixedLeftTableWidth + "px") , 
                    overflow: 'hidden'
                  }, 
                  x === 'Body' &&  
                  {  
                    width: (_vm.mainTableWidth + "px"), 
                    marginLeft:  (_vm.fixedLeftTableWidth + "px") , 
                    height: (_vm.calcTableHeight + "px"),
                    overflow: 'auto' 
                  }]),attrs:{"name":("NormalTable" + x)}},[_c('table-frame',_vm._b({},'table-frame',_vm.propsToNormalTable,false),[_c(("Table" + x),_vm._b({tag:"component"},'component',_vm.propsToNormalTable,false))],1)],1),(_vm.leftFixedColumns.length)?_c('div',{staticClass:"-left-fixed -fixed-table",style:([ x === 'Body' &&  { height: (_vm.fixedTableHeight + "px"), overflow: 'hidden' }]),attrs:{"name":("LeftFixedTable" + x)}},[_c('table-frame',_vm._b({attrs:{"left-fixed":""}},'table-frame',_vm.propsToLeftFixedTable,false),[_c(("Table" + x),_vm._b({tag:"component",attrs:{"left-fixed":""}},'component',_vm.propsToLeftFixedTable,false))],1)],1):_vm._e(),(_vm.rightFixedColumns.length && !_vm.pending)?_c('div',{staticClass:"-right-fixed -fixed-table",attrs:{"name":("RightFixedTable" + x)}},[_c('table-frame',_vm._b({attrs:{"right-fixed":""}},'table-frame',_vm.propsToRightFixedTable,false),[_c(("Table" + x),_vm._b({tag:"component",attrs:{"right-fixed":""}},'component',_vm.propsToRightFixedTable,false))],1)],1):_vm._e()]):(x == 'Body')?_c('div',{key:index},[_vm._m(0,true)]):_vm._e()]})],2):_c('div',{ref:"table",attrs:{"name":"SimpleTable"}},[_c('table-frame',_vm._b({attrs:{"is-simple-table":""}},'table-frame',_vm.propsToNormalTable,false),[_c('table-header',_vm._b({attrs:{"is-simple-table":""}},'table-header',_vm.propsToNormalTable,false)),_c('table-body',_vm._b({ref:"table_body",attrs:{"is-simple-table":""}},'table-body',_vm.propsToNormalTable,false)),(_vm.summary)?_c('table-footer',_vm._b({attrs:{"is-simple-table":""}},'table-footer',_vm.propsToNormalTable,false)):_vm._e()],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner"},[_c('div',{staticClass:"rect1"}),_c('div',{staticClass:"rect2"}),_c('div',{staticClass:"rect3"}),_c('div',{staticClass:"rect4"}),_c('div',{staticClass:"rect5"})])}]

export { render, staticRenderFns }