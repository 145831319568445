<template>
    <multiselect @input="change"
                 ref="select"
                v-model="selected"
                selectLabel=""
                deselectLabel=""
                label="title"
                track-by="id"
                :multiple="multiple"
                :show-labels="false"
                :placeholder="placeholder"
                :options="options"
                :loading="loading"
                :searchable="searchable"
                :max-height="600"
                :internal-search="true"
                :close-on-select="true"
                :show-no-results="true"
                :hide-selected="true"
                @search-change="search">
    <span slot="noResult">не найдено</span>
    <span slot="noOptions">пусто</span>
  </multiselect>
</template>
<script>
import {HOST} from '../../../../config.js'
import Multiselect from 'vue-multiselect'
import SelectBase from '../SelectMixin'
export default {
  components: {Multiselect},
  mixins: [SelectBase],
  name: "main-id-select",
  data: () => ({
    url: HOST + 'api/org/main-ids/'
  })
}
</script>