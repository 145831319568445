<template>
  <tbody @keydown.38="up" @keydown.40="down" v-on:keyup.enter="enterClicked" tabindex="0">
    <template v-if="data.length">
      <template v-for="(item, indexData) in data">
        <tr :style="item['style']" :class="{'selected-table-row': selected === item.id }" :key="indexData" @dblclick="dblClicked(item)" @click="clicked(item)" >
          <td class="text-left align-middle" v-if="shouldRenderSelection">
            <multi-select :selection="selection" :row="item" />
          </td>
          <td v-for="(col, index) in tableColumns" :key="index" 
            :class="col.tdClass"
            class="align-middle text-left"
            :title="item[col.field]"
            v-tippy="{ arrow : true, interactive : true,  duration: 0, trigger: 'manually' }"

            @mouseenter="onTippyMouseEnter"
            @mouseleave="onTippyMouseLeave"
            :style="col.tdStyle">
            <!-- <td> component (tdComp) -->
            <component
              v-if="col.tdComp"
              :is="forDynCompIs(col.tdComp)"
              :row="item"
              :params="col.tdComp.params"
              :field="col.field"
              :value="item[col.field]"
              :nested="item.__nested__"
              v-bind="$props">
            </component>
            <template v-else>
              {{ item[col.field] }}
            </template>
          </td>
        </tr>
        <transition name="fade" :key="indexData">
          <tr style="background-color: white" v-if="item.__nested__ && item.__nested__.visible">
            <td :colspan="colLen" style="padding: 0">
              <!-- nested component -->
              <component
                :is="forDynCompIs(item.__nested__.comp)"
                :row="item"
                :nested="item.__nested__"
                v-bind="$props">
              </component>
            </td>
          </tr>
        </transition>
      </template>
    </template>
    <tr v-else-if="!leftFixed && !rightFixed">
      <td :colspan="colLen" class="text-center text-muted">
        {{ $i18nForDatatable('No Data') }} 
      </td>
    </tr>
  </tbody>
</template>
<script>
import MultiSelect from './MultiSelect.vue'
import props from '../_mixins/props'
import shouldRenderSelection from '../_mixins/shouldRenderSelection'

export default {
  name: 'TableBody',
  components: { MultiSelect },
  mixins: [props, shouldRenderSelection],
  data: () => ({
    selected: null,
  }),
  methods: {
    up() {
      let index = this.data.findIndex(x => x.id === this.selected)
      if (typeof this.data[index - 1] !== 'undefined') {
        let current = this.data[index - 1]
        this.selected = current.id
        if (typeof this.clickSignal !== 'undefined')
          this.$root.$emit(this.clickSignal, current)
      }
    },
    down() {
      let index = this.data.findIndex(x => x.id === this.selected)
      if (typeof this.data[index + 1] !== 'undefined') {
        let current = this.data[index + 1]
        this.selected = current.id
        this.selected = current.id
        if (typeof this.clickSignal !== 'undefined')
          this.$root.$emit(this.clickSignal, current)
      }
    },
    setFirstSelected() {
      if(this.data && this.data.length > 0) {
        this.selected = this.data[0].id
        this.$root.$emit(this.clickSignal, this.data[0])
      }
    },
    select(id) {
      this.selected = id;
      let index = this.data.findIndex(x => x.id === this.selected)
      if (typeof this.data[index] !== 'undefined') {
        let current = this.data[index]
        if (typeof this.clickSignal !== 'undefined')
          this.$root.$emit(this.clickSignal, current)
      }
    },
    clicked(item) {
      this.selected = item.id;
      if (this.clickSignal)
        this.$root.$emit(this.clickSignal, item)
    },
    dblClicked(item) {
      this.selected = item.id;
      if (this.dblClickSignal)
        this.$root.$emit(this.dblClickSignal, item)
    },
    enterClicked() {
      let index = this.data.findIndex(x => x.id === this.selected)
      if (typeof this.data[index] !== 'undefined') {
        let current = this.data[index]
        if (typeof this.enterSignal !== 'undefined')
          this.$root.$emit(this.enterSignal, current)
      }
    },
    onTippyMouseEnter(e){
      if(e.target._tippy != null && (e.target.offsetWidth < e.target.scrollWidth)){
        e.target._tippy.show();
      }
    },
    onTippyMouseLeave(e){
      if(e.target._tippy != null && (e.target.offsetWidth < e.target.scrollWidth)){
        e.target._tippy.hide();
      }
    }
  },
  computed: {
    colLen () {
      return this.columns.length + !!this.selection
    },
    tableColumns() {
      if(this.leftFixed){
        return this.columns.filter(col => col.fixed === true || col.fixed === 'left' );
      } else if (this.rightFixed){
        return this.columns.filter(col => col.fixed === 'right' );
      } else {
        return this.columns.filter(col => col.fixed !== true && col.fixed !== 'left' && col.fixed !== 'right');
      }
    }
  }
}
</script>
