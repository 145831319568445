import Datatable from './index.vue'
import localeRu from '../locale/ru-RU'
Datatable.install = (Vue, options) => {
  const locale = options ? options.locale : localeRu

  // this might be the simplest i18n solution
  Vue.prototype.$i18nForDatatable = srcTxt => locale[srcTxt] || srcTxt

  Vue.component('Datatable', Datatable)
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Datatable)
}

export default Datatable
