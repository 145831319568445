
<script>
  export default {
    data: ()=> ({
      is_visible_modal: false
    }),
    props: {
      styleModal: {
        default: ''
      },
      styleDialog: {
        default: ''
      },
      fixed: {
        default: false
      },
      drag: {
        default: true
      },
      large: {
        default: false
      },
      extraLarge: {
        default: false
      },
      modalHeaderClass: {
        default: 'pb-1'
      },
      modalBodyClass: {
        default: ''
      },
      loading: {
        default: false
      },
      loading_accept: {
        default: false
      },
      accept_text: {
        default: 'Сохранить'
      },
    },
    name: 'modal',
    methods: {
      close() {
        /*if (this.drag) {
          // eslint-disable-next-line no-undef
          $(this.$refs.modal.querySelector('.modal-dialog')).draggable({disabled: true})
        }*/
        this.is_visible_modal = false
        this.$emit('close');
      },
      focus() {
        this.$nextTick(()=>{
           this.$refs.modal.focus()
        })
      },
      enter() {
        this.$emit('enter');
      },
      left() {
        this.$emit('left');
      },
      right() {
        this.$emit('right');
      },
      accept() {
        this.$emit('accept');
      },
      show() {
        this.is_visible_modal = true
        /*if (this.drag) {
          this.$nextTick(()=>{
            // eslint-disable-next-line no-undef
            $(this.$refs.modal.querySelector('.modal-dialog')).draggable({
              handle: ".modal-header"
            })
          })
        }*/
        this.focus()
      }
    },
  };
</script>
<template>
  <div ref="modal" @keydown.enter="enter" @keydown.esc="close" @keydown.left="left" @keydown.right="right" v-if="is_visible_modal" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-modal="true" v-bind:style="styleModal" style="padding-right: 15px; display: block;">
    <div :class="{'modal-lg': large, 'modal-xl': extraLarge, 'fixed-modal': fixed }" class="modal-dialog shadow-lg modal-dialog-scrollable " v-bind:style="styleDialog" role="document">
      <div class="modal-content shadow-lg ">
         <header
          style="cursor: move"
          class="modal-header"
          :class="modalHeaderClass"
          id="modalTitle">
          <slot name="header">
            <slot name="title"></slot>
            <button tabindex="-1" @click="close" class="close" type="button" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </slot>
        </header>
        <section class="modal-body pt-0" id="modalDescription" :class="modalBodyClass">
          <div v-if="loading" class="text-center">
            <i class="fas fa-sync fa-spin"></i>
          </div>
          <div v-else class="modal-form">
            <slot name="body"></slot>
          </div>
        </section>
        <footer class="modal-footer">
          <slot name="footer">
            <button @click="accept" :disabled="loading_accept===true" class="btn btn-sm btn-success ml-2">{{accept_text}}
              <i v-show="loading_accept===true" class="fa fa-sync fa-spin"></i>
            </button>
          </slot>
        </footer>
      </div>
    </div>
  </div>
</template>
