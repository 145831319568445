import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './components/Dashboard.vue'
import NewsIndex from './components/News/NewsIndex'
import LoginEnterEmail from './components/Auth/LoginEnter'
import store from './store'
import OrgInfo from './components/OrgInfo/OrgInfoIndex'
import StatisticIndex from "@/components/Statistic/StatisticIndex";

Vue.use(Router)

const routes = [

    { path: '/login',
      name: 'login',
      component: LoginEnterEmail,
    },
    {
      path: '/',
      name: 'dashboard',
      component: Dashboard,
      children: [
        {
          path: ":tab/news",
          props: true,
          component: NewsIndex,
          meta: {
            keepAlive: true,
            requireAuth: true,
          }
        },
        {
          path: ":tab/org-info",
          props: true,
          component: OrgInfo,
          meta: {
            keepAlive: true,
            requireAuth: true,
          },
        },
        {
          path: ":tab/statistic",
          props: true,
          component: StatisticIndex,
          meta: {
            keepAlive: true,
            requireAuth: true,
          },
        },
      ],
      meta: {
        requireAuth: true,
      }
    }
  ]

const router = new Router({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    if (store.state.token) {
      next()
    } else {
      next({
        path: '/login',
        query: {redirect: to.fullPath}
      })
    }
  } else {
    next()
  }
})

export default router
