<template>
  <div>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive===true" :key="$route.fullPath" ></router-view>
    </keep-alive>
    <router-view v-if="$route.meta.keepAlive===false" :key="$route.fullPath"></router-view>
  </div>

</template>

<script>
import { guid } from '../utils'

export default {
  name: 'Container',
  props: {
    name: String,
    label: String
  },
  data: () => ({
    uuid: guid()
  }),
  mounted(){

  },
  beforeDestroy(){

  }
}
</script>