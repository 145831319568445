import {HOST} from '../../config.js'
const URI_LIST_FIELDS = 'api/org/info-fields'
const URI_LIST_INFOS = 'api/org/infos'
export default {
  api_list_fields: function () {
    return HOST + URI_LIST_FIELDS
  },
  api_list_infos:  function () {
    return HOST + URI_LIST_INFOS
  },
  api_info_detail:  function (id) {
    return HOST + URI_LIST_INFOS + '/' + id
  }
}
