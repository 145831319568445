<template>
  <div class="row">
    <div class="col-12">
      <fieldset class="p-2">
        <legend>Данные по мобильному приложению <button @click="getStatistic" class="btn btn-success btn-round btn-fab btn-sm btn-fab-sm"><i class="fa fa-sync"></i></button></legend>
        <div class="d-flex">
          <calendar style="width: 10%!important;" :placeholder="'С даты'" :clickable="true" :type="'date'" :format="'DD.MM.YYYY'" :mask="'##.##.####'" v-bind:value="from_date" v-on:changed="from_date=$event; getStatistic()"/>
          <i style="cursor: pointer" @click="from_date=''; getStatistic()" class="mt-1 ml-1 fa fa-times"/>
          <span class="ml-3 mr-3"> - </span>
          <calendar style="width: 10%!important;" :clickable="true" :placeholder="'По дату'" :type="'date'" :format="'DD.MM.YYYY'" :mask="'##.##.####'" v-bind:value="to_date" v-on:changed="to_date=$event; getStatistic()"/>
          <i style="cursor: pointer" @click="to_date=''; getStatistic()" class="mt-1 ml-1 fa fa-times"/>
        </div>
        <table class="table table-bordered table-striped table-hover mt-3">
        <tbody v-if="result">
          <tr>
            <td>Пользователи</td>
            <td>
              {{result.users}}
            </td>
          </tr>
          <tr>
            <td>Платежи</td>
            <td>
              {{result.amount}} (кол.:{{result.amount_cnt}})
            </td>
          </tr>
        </tbody>
        <div v-else class="col-12 text-center">
          <i class="fa fa-sync fa-spin"></i>
        </div>
      </table>
      </fieldset>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import urls from "./urls";
//import moment from 'moment'
import Calendar from "@/components/Plugins/calendar/index";
export default {
  name: "StatisticIndex",
  components: {Calendar},
  data: () => ({
    result: null,
    from_date: '',//moment().startOf('month').add('days', 1).format('DD.MM.YYYY'),
    to_date: '',//moment().endOf('month').format('DD.MM.YYYY'),
    loading_statistic: false,
  }),
  mounted() {
    this.getStatistic()
  },
  methods: {
    getStatistic () {
      this.loading_statistic = true
      axios.get(urls.api_statistics() + `?from_date=${this.from_date}&to_date=${this.to_date}`)
        .then(response => {
          this.result = response.data.data
          this.loading_statistic = false
        })
        .catch((e) => {
          this.showError(e, 'Ошибка получения статистики')
          this.loading_statistic = false
        })
    },
  }
}
</script>