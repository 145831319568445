export default {
  props: ['tab'],
  mounted () {
    this.$root.$on('close_tab_' + this.tab, this.closeTab)
  },
  beforeDestroy () {
    this.$root.$off('close_tab_' + this.tab, this.closeTab)
  },
  methods: {
    closeTab() {
      this.$destroy()
    }
  }
}