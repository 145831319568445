import * as types from './store/mutation-types'
import axios from 'axios'
import store from './store/index'
import router from './router'

axios.interceptors.request.use(
  config => {
    /*if (!config.url.includes('payers-search') && !config.url.includes('employees-search')) {
    }*/
    if (store.state.token) {
      config.headers.Authorization = `JWT ${store.state.token}`
    }
    return config
  },
  err => {
    return Promise.reject(err)
  })

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          store.commit(types.LOGOUT, {
            router: router,
            redirect: '/login'
          })
          break
        case 500:
          break
      }
    }
    return Promise.reject(error.response)
  })
