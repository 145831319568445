<template>
  <modal
    ref="modal"
    :large="false"
    :loading="loading_detail"
    :loading_accept="loading_saving"
    @close="resetForm"
    @accept="save">
    <template v-slot:title>Новость</template>
    <template v-slot:body>
      <div class="row" v-if="render===false">
        <div class="col-12">
          <ValidationObserver ref="observer">
            <div style="display: flex;">
              <input class="ml-2" type="radio" id="by_org" value="by_org" v-model="instance.purpose" @change="changedRadio">
              <label class="ml-1" for="by_org">По УК</label>
              <input class="ml-2" type="radio" id="by_all" value="by_all" v-model="instance.purpose" @change="changedRadio">
              <label class="ml-1" for="by_all">По всем домам</label>
            </div>
            <template v-if="instance.purpose === 'by_org'" >
               <validation-provider rules="required" vid="main_id" name="подразделение" v-slot="{ errors }">
                <div class="form-group">
                  <label>Подразделение</label>
                  <main-id-select ref="main_id_select" :exclude_first_item="true" v-bind:value="instance.main_id" v-on:changed="$set(instance, 'main_id', $event.id); getStreets()"/>
                </div>
                <span class="text-danger">{{ errors[0] }}</span>
               </validation-provider>
            </template>
            <validation-provider vid="title" name="заголовок" rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label>Заголовок</label>
                <input placeholder="" class="form-control" v-model="instance.title"/>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider vid="description" name="новость" rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label>Новость</label>
                <textarea placeholder="" rows="5" class="form-control" v-model="instance.description"></textarea>
                <span class="text-danger">{{ errors[0] }}</span>
              </div>
            </validation-provider>
         </ValidationObserver>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col">
          <div class="text-center">
            <i class="fas fa-circle-notch fa-spin"></i>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '../Modal.vue'
import BaseModalMixin from "@/components/Mixins/BaseModalMixin";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import MainIdSelect from "@/components/Plugins/selects/main-ids-select";
export default {
  components: {MainIdSelect, Modal, ValidationProvider, ValidationObserver},
  mixins: [BaseModalMixin],
  name: "NewsModal",
  data() {
    return {
      render: false,
      instance: {
        purpose: 'by_org'
      }
    }
  },
  methods: {
    changedRadio() {
      this.render = true
      setTimeout(()=>{this.render=false}, 500);
    }
  }
}
</script>

